import * as Types from '../gql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SharedCollectionFragmentFragment = { __typename?: 'SharedCollection', collectionId?: any | null, creatorName: string, sharingKey: string, title: string, creatorId: any };

export type CollectionFragmentFragment = { __typename?: 'Collection', creatorId: any, id: any, sharingKey?: string | null, title?: string | null, sectionId?: any | null };

export type CollectionCreateMutationVariables = Types.Exact<{
  input: Types.CollectionCreateInput;
}>;


export type CollectionCreateMutation = { __typename?: 'Mutation', collectionCreate: { __typename?: 'Collection', creatorId: any, id: any, sharingKey?: string | null, title?: string | null, sectionId?: any | null } };

export type RemoveItemFromCollectionMutationVariables = Types.Exact<{
  input: Types.CollectionItemUpdateInput;
}>;


export type RemoveItemFromCollectionMutation = { __typename?: 'Mutation', removeItemFromCollection: boolean };

export type CollectionUpdateMutationVariables = Types.Exact<{
  input: Types.CollectionUpdateInput;
}>;


export type CollectionUpdateMutation = { __typename?: 'Mutation', collectionUpdate: { __typename?: 'Collection', creatorId: any, id: any, sharingKey?: string | null, title?: string | null, sectionId?: any | null } };

export type CollectionDeleteMutationVariables = Types.Exact<{
  input: Types.CollectionIdInput;
}>;


export type CollectionDeleteMutation = { __typename?: 'Mutation', collectionDelete: boolean };

export type SharedCollectionQueryVariables = Types.Exact<{
  input: Types.SharedCollectionInput;
}>;


export type SharedCollectionQuery = { __typename?: 'Query', sharedCollection: { __typename?: 'SharedCollection', collectionId?: any | null, creatorName: string, sharingKey: string, title: string, creatorId: any } };

export type CollectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CollectionsQuery = { __typename?: 'Query', collectionQuery: Array<{ __typename?: 'Collection', creatorId: any, id: any, sharingKey?: string | null, title?: string | null, sectionId?: any | null }> };

export type FollowCollectionMutationVariables = Types.Exact<{
  input: Types.CollectionFollowInput;
}>;


export type FollowCollectionMutation = { __typename?: 'Mutation', followCollection: { __typename?: 'SharedCollection', collectionId?: any | null, creatorName: string, sharingKey: string, title: string, creatorId: any } };

export type UnfollowCollectionMutationVariables = Types.Exact<{
  input: Types.CollectionUnfollowInput;
}>;


export type UnfollowCollectionMutation = { __typename?: 'Mutation', unfollowCollection: boolean };

export type FollowListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FollowListQuery = { __typename?: 'Query', collectionFollowsList: Array<{ __typename?: 'SharedCollection', collectionId?: any | null, creatorName: string, sharingKey: string, title: string, creatorId: any }> };

export const SharedCollectionFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sharedCollectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SharedCollection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionId"}},{"kind":"Field","name":{"kind":"Name","value":"creatorName"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"creatorId"}}]}}]} as unknown as DocumentNode;
export const CollectionFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"collectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Collection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"sectionId"}}]}}]} as unknown as DocumentNode;
export const CollectionCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"collectionCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionCreateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"collectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"collectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Collection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"sectionId"}}]}}]} as unknown as DocumentNode;
export type CollectionCreateMutationFn = Apollo.MutationFunction<CollectionCreateMutation, CollectionCreateMutationVariables>;

/**
 * __useCollectionCreateMutation__
 *
 * To run a mutation, you first call `useCollectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectionCreateMutation, { data, loading, error }] = useCollectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionCreateMutation(baseOptions?: Apollo.MutationHookOptions<CollectionCreateMutation, CollectionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectionCreateMutation, CollectionCreateMutationVariables>(CollectionCreateDocument, options);
      }
export type CollectionCreateMutationHookResult = ReturnType<typeof useCollectionCreateMutation>;
export type CollectionCreateMutationResult = Apollo.MutationResult<CollectionCreateMutation>;
export type CollectionCreateMutationOptions = Apollo.BaseMutationOptions<CollectionCreateMutation, CollectionCreateMutationVariables>;
export const RemoveItemFromCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"removeItemFromCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionItemUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeItemFromCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveItemFromCollectionMutationFn = Apollo.MutationFunction<RemoveItemFromCollectionMutation, RemoveItemFromCollectionMutationVariables>;

/**
 * __useRemoveItemFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCollectionMutation, { data, loading, error }] = useRemoveItemFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromCollectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemFromCollectionMutation, RemoveItemFromCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemFromCollectionMutation, RemoveItemFromCollectionMutationVariables>(RemoveItemFromCollectionDocument, options);
      }
export type RemoveItemFromCollectionMutationHookResult = ReturnType<typeof useRemoveItemFromCollectionMutation>;
export type RemoveItemFromCollectionMutationResult = Apollo.MutationResult<RemoveItemFromCollectionMutation>;
export type RemoveItemFromCollectionMutationOptions = Apollo.BaseMutationOptions<RemoveItemFromCollectionMutation, RemoveItemFromCollectionMutationVariables>;
export const CollectionUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"collectionUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"collectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"collectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Collection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"sectionId"}}]}}]} as unknown as DocumentNode;
export type CollectionUpdateMutationFn = Apollo.MutationFunction<CollectionUpdateMutation, CollectionUpdateMutationVariables>;

/**
 * __useCollectionUpdateMutation__
 *
 * To run a mutation, you first call `useCollectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectionUpdateMutation, { data, loading, error }] = useCollectionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CollectionUpdateMutation, CollectionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectionUpdateMutation, CollectionUpdateMutationVariables>(CollectionUpdateDocument, options);
      }
export type CollectionUpdateMutationHookResult = ReturnType<typeof useCollectionUpdateMutation>;
export type CollectionUpdateMutationResult = Apollo.MutationResult<CollectionUpdateMutation>;
export type CollectionUpdateMutationOptions = Apollo.BaseMutationOptions<CollectionUpdateMutation, CollectionUpdateMutationVariables>;
export const CollectionDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"collectionDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionIdInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type CollectionDeleteMutationFn = Apollo.MutationFunction<CollectionDeleteMutation, CollectionDeleteMutationVariables>;

/**
 * __useCollectionDeleteMutation__
 *
 * To run a mutation, you first call `useCollectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectionDeleteMutation, { data, loading, error }] = useCollectionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CollectionDeleteMutation, CollectionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectionDeleteMutation, CollectionDeleteMutationVariables>(CollectionDeleteDocument, options);
      }
export type CollectionDeleteMutationHookResult = ReturnType<typeof useCollectionDeleteMutation>;
export type CollectionDeleteMutationResult = Apollo.MutationResult<CollectionDeleteMutation>;
export type CollectionDeleteMutationOptions = Apollo.BaseMutationOptions<CollectionDeleteMutation, CollectionDeleteMutationVariables>;
export const SharedCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"sharedCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SharedCollectionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sharedCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sharedCollectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sharedCollectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SharedCollection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionId"}},{"kind":"Field","name":{"kind":"Name","value":"creatorName"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"creatorId"}}]}}]} as unknown as DocumentNode;

/**
 * __useSharedCollectionQuery__
 *
 * To run a query within a React component, call `useSharedCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedCollectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSharedCollectionQuery(baseOptions: Apollo.QueryHookOptions<SharedCollectionQuery, SharedCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharedCollectionQuery, SharedCollectionQueryVariables>(SharedCollectionDocument, options);
      }
export function useSharedCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharedCollectionQuery, SharedCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharedCollectionQuery, SharedCollectionQueryVariables>(SharedCollectionDocument, options);
        }
export type SharedCollectionQueryHookResult = ReturnType<typeof useSharedCollectionQuery>;
export type SharedCollectionLazyQueryHookResult = ReturnType<typeof useSharedCollectionLazyQuery>;
export type SharedCollectionQueryResult = Apollo.QueryResult<SharedCollectionQuery, SharedCollectionQueryVariables>;
export const CollectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"collections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"collectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"collectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Collection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"sectionId"}}]}}]} as unknown as DocumentNode;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, options);
      }
export function useCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, options);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = Apollo.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const FollowCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"followCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionFollowInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"followCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sharedCollectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sharedCollectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SharedCollection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionId"}},{"kind":"Field","name":{"kind":"Name","value":"creatorName"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"creatorId"}}]}}]} as unknown as DocumentNode;
export type FollowCollectionMutationFn = Apollo.MutationFunction<FollowCollectionMutation, FollowCollectionMutationVariables>;

/**
 * __useFollowCollectionMutation__
 *
 * To run a mutation, you first call `useFollowCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followCollectionMutation, { data, loading, error }] = useFollowCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowCollectionMutation(baseOptions?: Apollo.MutationHookOptions<FollowCollectionMutation, FollowCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowCollectionMutation, FollowCollectionMutationVariables>(FollowCollectionDocument, options);
      }
export type FollowCollectionMutationHookResult = ReturnType<typeof useFollowCollectionMutation>;
export type FollowCollectionMutationResult = Apollo.MutationResult<FollowCollectionMutation>;
export type FollowCollectionMutationOptions = Apollo.BaseMutationOptions<FollowCollectionMutation, FollowCollectionMutationVariables>;
export const UnfollowCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"unfollowCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionUnfollowInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unfollowCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UnfollowCollectionMutationFn = Apollo.MutationFunction<UnfollowCollectionMutation, UnfollowCollectionMutationVariables>;

/**
 * __useUnfollowCollectionMutation__
 *
 * To run a mutation, you first call `useUnfollowCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowCollectionMutation, { data, loading, error }] = useUnfollowCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowCollectionMutation, UnfollowCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowCollectionMutation, UnfollowCollectionMutationVariables>(UnfollowCollectionDocument, options);
      }
export type UnfollowCollectionMutationHookResult = ReturnType<typeof useUnfollowCollectionMutation>;
export type UnfollowCollectionMutationResult = Apollo.MutationResult<UnfollowCollectionMutation>;
export type UnfollowCollectionMutationOptions = Apollo.BaseMutationOptions<UnfollowCollectionMutation, UnfollowCollectionMutationVariables>;
export const FollowListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"followList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionFollowsList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sharedCollectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sharedCollectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SharedCollection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionId"}},{"kind":"Field","name":{"kind":"Name","value":"creatorName"}},{"kind":"Field","name":{"kind":"Name","value":"sharingKey"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"creatorId"}}]}}]} as unknown as DocumentNode;

/**
 * __useFollowListQuery__
 *
 * To run a query within a React component, call `useFollowListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFollowListQuery(baseOptions?: Apollo.QueryHookOptions<FollowListQuery, FollowListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowListQuery, FollowListQueryVariables>(FollowListDocument, options);
      }
export function useFollowListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowListQuery, FollowListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowListQuery, FollowListQueryVariables>(FollowListDocument, options);
        }
export type FollowListQueryHookResult = ReturnType<typeof useFollowListQuery>;
export type FollowListLazyQueryHookResult = ReturnType<typeof useFollowListLazyQuery>;
export type FollowListQueryResult = Apollo.QueryResult<FollowListQuery, FollowListQueryVariables>;