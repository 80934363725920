// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Collection } from '~/common/gql';

const ORPHAN_TAG_ID = -1;
const ORPHAN_TAG_NAME = 'Untagged';
const ALL_TAG_ID = -3;
const ALL_TAG_NAME = 'All items';
const TRASH_TAG_ID = -2;
const TRASH_TAG_NAME = 'Trash';

const createOrphanTag = (creatorId: number): Collection => {
  return { id: ORPHAN_TAG_ID, title: ORPHAN_TAG_NAME, creatorId };
};

const createAllTag = (creatorId: number): Collection => {
  return { id: ALL_TAG_ID, title: ALL_TAG_NAME, creatorId };
};

const createTrashTag = (creatorId: number) => {
  return { id: TRASH_TAG_ID, title: TRASH_TAG_NAME, creatorId };
};

export const systemTag = {
  ids: [ALL_TAG_ID, ORPHAN_TAG_ID, TRASH_TAG_ID],
  all: {
    id: ALL_TAG_ID,
    title: ALL_TAG_NAME,
  },
  orphan: {
    id: ORPHAN_TAG_ID,
    title: ORPHAN_TAG_NAME,
  },
  trash: {
    id: TRASH_TAG_ID,
    title: TRASH_TAG_NAME,
  },
  createOrphanTag,
  createTrashTag,
  createAllTag,
};

export const isSystemTag = (id: Collection['id']) => {
  return systemTag.ids.includes(id);
};
