// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  As,
  Box,
  BoxProps,
  Button,
  chakra,
  ChakraStyledOptions,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';

/**
 * Chakra UI component with added convenince props.
 */
export interface BaseFancyProps {
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  exactHeight?: string;
}

// TODO: Remove fancy props
export interface FancyProps extends BoxProps, BaseFancyProps {}

const getDisplay = <T extends As>(Component: T) => {
  switch (Component) {
    case Box:
    case Text:
    case Heading:
      return 'block';
    case Flex:
    case Stack:
      return 'flex';
    case Button:
    case IconButton:
      return 'inline-flex';
    default:
      return 'initial';
  }
};

export const shouldForwardProp: ChakraStyledOptions['shouldForwardProp'] = (
  prop,
) => !['mobileOnly', 'desktopOnly', 'exactHeight'].includes(prop);

export const baseStyle: (
  display: string,
) => Extract<ChakraStyledOptions['baseStyle'], Function> =
  (display) => (props) => {
    const { mobileOnly, desktopOnly, exactHeight } = props as BaseFancyProps;

    return {
      ...(exactHeight && {
        minH: exactHeight,
        maxH: exactHeight,
        h: exactHeight,
      }),
      ...(desktopOnly && { display: ['none', 'none', display] }),
      ...(mobileOnly && { display: [display, display, 'none'] }),
    };
  };

const FancyFactory = <T extends As>(Component: T) =>
  chakra<T, BaseFancyProps>(Component, {
    shouldForwardProp,
    baseStyle: baseStyle(getDisplay(Component)),
  });

export const FancyBox = FancyFactory(Box);
export const FancyFlex = FancyFactory(Flex);
export const FancyText = FancyFactory(Text);
export const FancyStack = FancyFactory(Stack);
export const FancyIconButton = FancyFactory(IconButton);
export const FancyButton = FancyFactory(Button);
export const FancyHeading = FancyFactory(Heading);
