// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Collection, CollectionItem } from '~/common/gql';
import { EnhancedCollection } from './EnhancedCollection';

interface Props {
  all: Collection[] | undefined;
  selected: Pick<CollectionItem, 'collectionId'>[] | undefined;
}

export const getEnhancedCollections = ({
  all,
  selected,
}: Props): EnhancedCollection[] => {
  const collections = all ?? [];
  const selectedCollections = selected ?? [];
  const isSelected = (id: Collection['id']) => {
    return !!selectedCollections.find((it) => it.collectionId === id);
  };

  const enhancedCollections: EnhancedCollection[] =
    collections.map((it) => {
      return {
        ...it,
        selected: isSelected(it.id),
      };
    }) ?? [];

  return enhancedCollections;
};
