import * as Types from '../gql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SectionFragmentFragment = { __typename?: 'Section', creatorId: any, id: any, title?: string | null, collapsed?: boolean | null };

export type SectionCreateMutationVariables = Types.Exact<{
  input: Types.SectionCreateInput;
}>;


export type SectionCreateMutation = { __typename?: 'Mutation', sectionCreate: { __typename?: 'Section', creatorId: any, id: any, title?: string | null, collapsed?: boolean | null } };

export type SectionUpdateMutationVariables = Types.Exact<{
  input: Types.SectionUpdateInput;
}>;


export type SectionUpdateMutation = { __typename?: 'Mutation', sectionUpdate: { __typename?: 'Section', creatorId: any, id: any, title?: string | null, collapsed?: boolean | null } };

export type SectionPreferenceUpdateMutationVariables = Types.Exact<{
  input: Types.SectionPreferenceUpdateInput;
}>;


export type SectionPreferenceUpdateMutation = { __typename?: 'Mutation', sectionPreferenceUpdate: { __typename?: 'Section', creatorId: any, id: any, title?: string | null, collapsed?: boolean | null } };

export type SectionDeleteMutationVariables = Types.Exact<{
  input: Types.SectionIdInput;
}>;


export type SectionDeleteMutation = { __typename?: 'Mutation', sectionDelete: boolean };

export type ListSectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListSectionsQuery = { __typename?: 'Query', listSections: Array<{ __typename?: 'Section', creatorId: any, id: any, title?: string | null, collapsed?: boolean | null }> };

export const SectionFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"collapsed"}}]}}]} as unknown as DocumentNode;
export const SectionCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sectionCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SectionCreateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sectionCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"collapsed"}}]}}]} as unknown as DocumentNode;
export type SectionCreateMutationFn = Apollo.MutationFunction<SectionCreateMutation, SectionCreateMutationVariables>;

/**
 * __useSectionCreateMutation__
 *
 * To run a mutation, you first call `useSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionCreateMutation, { data, loading, error }] = useSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionCreateMutation(baseOptions?: Apollo.MutationHookOptions<SectionCreateMutation, SectionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SectionCreateMutation, SectionCreateMutationVariables>(SectionCreateDocument, options);
      }
export type SectionCreateMutationHookResult = ReturnType<typeof useSectionCreateMutation>;
export type SectionCreateMutationResult = Apollo.MutationResult<SectionCreateMutation>;
export type SectionCreateMutationOptions = Apollo.BaseMutationOptions<SectionCreateMutation, SectionCreateMutationVariables>;
export const SectionUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sectionUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SectionUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sectionUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"collapsed"}}]}}]} as unknown as DocumentNode;
export type SectionUpdateMutationFn = Apollo.MutationFunction<SectionUpdateMutation, SectionUpdateMutationVariables>;

/**
 * __useSectionUpdateMutation__
 *
 * To run a mutation, you first call `useSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionUpdateMutation, { data, loading, error }] = useSectionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SectionUpdateMutation, SectionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SectionUpdateMutation, SectionUpdateMutationVariables>(SectionUpdateDocument, options);
      }
export type SectionUpdateMutationHookResult = ReturnType<typeof useSectionUpdateMutation>;
export type SectionUpdateMutationResult = Apollo.MutationResult<SectionUpdateMutation>;
export type SectionUpdateMutationOptions = Apollo.BaseMutationOptions<SectionUpdateMutation, SectionUpdateMutationVariables>;
export const SectionPreferenceUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sectionPreferenceUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SectionPreferenceUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sectionPreferenceUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"collapsed"}}]}}]} as unknown as DocumentNode;
export type SectionPreferenceUpdateMutationFn = Apollo.MutationFunction<SectionPreferenceUpdateMutation, SectionPreferenceUpdateMutationVariables>;

/**
 * __useSectionPreferenceUpdateMutation__
 *
 * To run a mutation, you first call `useSectionPreferenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionPreferenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionPreferenceUpdateMutation, { data, loading, error }] = useSectionPreferenceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionPreferenceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SectionPreferenceUpdateMutation, SectionPreferenceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SectionPreferenceUpdateMutation, SectionPreferenceUpdateMutationVariables>(SectionPreferenceUpdateDocument, options);
      }
export type SectionPreferenceUpdateMutationHookResult = ReturnType<typeof useSectionPreferenceUpdateMutation>;
export type SectionPreferenceUpdateMutationResult = Apollo.MutationResult<SectionPreferenceUpdateMutation>;
export type SectionPreferenceUpdateMutationOptions = Apollo.BaseMutationOptions<SectionPreferenceUpdateMutation, SectionPreferenceUpdateMutationVariables>;
export const SectionDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sectionDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SectionIdInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sectionDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type SectionDeleteMutationFn = Apollo.MutationFunction<SectionDeleteMutation, SectionDeleteMutationVariables>;

/**
 * __useSectionDeleteMutation__
 *
 * To run a mutation, you first call `useSectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionDeleteMutation, { data, loading, error }] = useSectionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SectionDeleteMutation, SectionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SectionDeleteMutation, SectionDeleteMutationVariables>(SectionDeleteDocument, options);
      }
export type SectionDeleteMutationHookResult = ReturnType<typeof useSectionDeleteMutation>;
export type SectionDeleteMutationResult = Apollo.MutationResult<SectionDeleteMutation>;
export type SectionDeleteMutationOptions = Apollo.BaseMutationOptions<SectionDeleteMutation, SectionDeleteMutationVariables>;
export const ListSectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"listSections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listSections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"sectionFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"sectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"creatorId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"collapsed"}}]}}]} as unknown as DocumentNode;

/**
 * __useListSectionsQuery__
 *
 * To run a query within a React component, call `useListSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSectionsQuery(baseOptions?: Apollo.QueryHookOptions<ListSectionsQuery, ListSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSectionsQuery, ListSectionsQueryVariables>(ListSectionsDocument, options);
      }
export function useListSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSectionsQuery, ListSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSectionsQuery, ListSectionsQueryVariables>(ListSectionsDocument, options);
        }
export type ListSectionsQueryHookResult = ReturnType<typeof useListSectionsQuery>;
export type ListSectionsLazyQueryHookResult = ReturnType<typeof useListSectionsLazyQuery>;
export type ListSectionsQueryResult = Apollo.QueryResult<ListSectionsQuery, ListSectionsQueryVariables>;