// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { Collection } from '~/common/gql';

interface Props<T> {
  substring?: string;
  collections: T[];
  // if true, result will return true if substring is empty
  matchEmpty?: boolean;
}

/**
 *
 * @param param0
 * @returns list of collections with titles that contain the input substring (case insensitive).
 */
export const filterCollectionByTitleSubstring = <
  T extends Pick<Collection, 'title'>,
>({
  substring,
  collections,
  matchEmpty = true,
}: Props<T>): T[] => {
  const isSubstringEmpty = isEmpty(trim(substring));
  const allCollections = collections; // all tags
  const compareCaseInsensitive = (str: string, substring: string) => {
    return str.toLowerCase().includes(substring.toLowerCase());
  };

  const matches =
    matchEmpty && isSubstringEmpty
      ? allCollections
      : collections.filter((it) => compareCaseInsensitive(it.title, substring));

  return matches;
};
