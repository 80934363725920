// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useCollectionCreateMutation } from '~/common/gql/collection.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useCollectionCreateQueryHelper = createMutationHelperHook(
  useCollectionCreateMutation,
  {
    errorMessage: 'There was an error creating this collection: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.ADD_COLLECTION,
        label: variables.input.title,
      }),
      afterMutation: (variables, data) => ({
        actionType: ActionType.COLLECTION_ADDED,
        srcCollectionId: data?.collectionCreate?.id,
      }),
    },
  },
);
