// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { Collection } from '~/common/gql';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { useMeQuery } from '~/common/gql/user.generated';
import { systemTag } from '~/common/utilities/systemTag';

export interface CollectionResults {
  userStandardCollections: Collection[];
  userCollections: Collection[];
}

export const useCollectionsQueryHelper = (): CollectionResults => {
  const meQuery = useMeQuery();
  const collectionQuery = useCollectionsQuery();

  const userId = meQuery.data?.self?.id;

  const oc = systemTag.createOrphanTag(userId);
  const ac = systemTag.createAllTag(userId);
  const tc = systemTag.createTrashTag(userId);

  // if user is not logged in, then don't show the standard collections
  const userStandardCollections = useMemo(() => {
    return userId ? [oc, tc, ac] : [];
  }, [ac, oc, tc, userId]);

  const userCollections = useMemo(() => {
    return sortBy(collectionQuery.data?.collectionQuery ?? [], (it) =>
      it.title?.toLowerCase(),
    );
  }, [collectionQuery.data?.collectionQuery]);

  const output = useMemo(() => {
    return {
      userStandardCollections,
      userCollections,
    };
  }, [userCollections, userStandardCollections]);

  return output;
};
