// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Item } from '~/common/gql';

/**
 * Returns True item contains a todo.
 * Handles cases where item is undefined.
 *
 * @param item
 * @returns True  if item contains todo.
 *          False otherwise.
 */
export const getIsTodo = (item: Pick<Item, 'todo'> | undefined) => {
  const todo = first(item?.todo);
  return !isNil(todo);
};

export const getIsTodoDone = (item: Pick<Item, 'todo'> | undefined) => {
  const todo = first(item?.todo);
  const isTodo = getIsTodo(item);
  return isTodo && !isNil(todo?.markedDoneAt);
};

export const getIsItemTrashed = (item: Pick<Item, 'trashedAt'> | undefined) => {
  return !isNil(item.trashedAt);
};

export const getIsNoteExists = (item: Pick<Item, 'note'> | undefined) => {
  return !isEmpty(item?.note);
};

export const getItemTitle = (item: Pick<Item, 'title' | 'urlData' | 'url'>) => {
  return item.title ?? item.urlData?.title ?? item.url;
};
